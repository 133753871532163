/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
.progress-bar{
  background-color: #0aa89e;
  width: 5%
}
#prog1{
  background-color: red;
}

.no-padding {
  padding: 0;
}

.no-padding-top {
  padding-top: 0;
}

.no-padding-bottom {
  padding-bottom: 0;
}

.no-padding-left {
  padding-left: 0;
}

.no-padding-right {
  padding-right: 0;
}

.badge-calibrat {
  color: #FFFFFF;
  background-color: #0ec2d0;
}

input[readonly] {
  background-color: #ffffff !important;
}
input[disabled] {
  background-color: #e9ecef !important;
}

.c-token {
  height: auto !important;
  width:auto !important;
  margin-top: 2px !important;
  background: #0079FE !important;
  color: #fff!important;

}

.registration_login_bg_image{
  background: url("./assets/img/login-bg.jpg");
  background-size: cover;
}

/*    Assessment Module Common CSS */
.blank-ans {
  font-weight: 600 !important;
  border-bottom: 0.1rem solid !important;
  padding: 5px 5px !important;
  color: #7abe44 !important;
  border-color: #212529 !important;
}


.blue-dot {
  font-size: 15px;
  position: absolute;
  top: -1.5rem;
  color: #41b3d4;
  left: 4.3rem;
  border: 0;
  background: transparent;
}
.marks-input {
  width: 50%;
  text-align: center;
  text-decoration: none;
  box-shadow: none;
}
.popover-content {
  width: 18rem;
  overflow: hidden;
}
.bs-popover-auto[x-placement^=left] .arrow, .bs-popover-left .arrow {
  margin: 0;
  right: calc((.5rem + -10px) * -1)
}
.ng2-tag-input-remove{
  display: none !important;
}

.cke_button__simplelink{
  display: none !important;
}

.eye-icon-pwd {
  border-radius: 0 0.25rem 0.25rem 0;
  background: transparent;
  color: darkgray;
  border-left-width: 0;
  cursor: pointer;
}
.rbw-0 {
  border-right-width: 0 !important;
  /*outline:0px  !important;*/
  /*-webkit-appearance:none;*/
  /*box-shadow: none !important;*/
}

.password-info-icon {
  vertical-align: middle;
  font-size: 18px !important;
}

span.flagInput > div.dropdown > button.dropbtn {
  border: 1px solid #ced4da !important;
  border-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  margin-left: 0 !important;
  padding-top: 6px ;
  padding-bottom: 6px ;
}

label#dial-code {
  display: block;
  margin-bottom: 0 !important;
  padding: 0.50rem 0.75rem 0.375rem 0.1rem !important;
  font-size: 16px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  border-left: 0;
  border-right: 0;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.full-width-container {
  width: 100%;
}

.ng-image-slider .ng-image-slider-container .main .main-inner .img-div {
  width: 205px;
  height: 100%;
  margin-right: 3px;
  margin-left: 3px;
  position: relative;
  border-radius: 0 !important;
  display: inline-block;
  box-shadow: none !important;
}

.MJXc-display {
  display: inline-block !important;
}

.flag {
  margin-right: 2px;
}

.card-thin {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.snapshot-badge {
  z-index: 999;
  position: absolute;
  top: 15px;
  left: 2px;
  height: 42px;
}


button.side-panel-btn:disabled {
  background: #efefef !important;
}

.srchbadge{

  margin-right: 20px;
  margin-left: auto;
  margin-bottom: 1px;
  float: left;
  border: none;
  text-align: center;
  font-size:large;
  padding: 0 12px;
  height: 30px !important;
  background-color: #e6e6e6;
  border-radius: 2rem !important;
  font-weight: bold;
}

.fa-close{
  margin-left: 2px;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@media (min-width: 992px) {
  .modal-xlg {
    max-width: 980px;
  }
}
